import React, { useState } from "react";
import { Illustration } from "telstra-ui/components/svg/Illustration";
import { Button } from "telstra-ui/components/button/Button";
import axios from "axios";
import { useLoaderData } from "react-router-dom";
import { Spinner } from "telstra-ui/components/spinner/spinner";
import { GenericAlert } from "telstra-ui/components/generic-alert/GenericAlert";
import config from "../../../config";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import pupa from "pupa";

const action = "cancel";

const Cancel = () => {
  const { correlationId, bookingRef, apptDetails } = useLoaderData();
  const [state, setState] = useState("");
  const [infoError, setInfoError] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const cancelAction = async () => {
    setState("loading");
    try {
      await axios.post(`${config.bookingUrl}${bookingRef}`, null, {
        params: {
          action,
        },
        headers: {
          "X-Correlation-ID": correlationId,
        },
      });
      setState("success");
    } catch (error) {
      setState("error");
      let errMsg;
      try {
        const errObj = error.response.data.errors[0];
        errMsg = config.errMap[errObj.code];
        setInfoError(config.infoErrorCodes.split(",").includes(errObj.code));
      } catch (error) {
        // Suppress errMsg lookup
      }
      errMsg = errMsg || config.errUnknown;
      setErrMsg(pupa(errMsg, { action }));
    }
  };

  const cancelCta = (
    <Button
      variant="secondary"
      label={config.cancelCancelCtaLabel}
      size="medium"
      onClick={() => {
        window.location.href = config.cancelCancelCtaUrl;
      }}
    />
  );
  const getFooter = () => {
    switch (state) {
      case "loading":
        return (
          <Box sx={{ m: 2 }}>
            <Spinner size="tl-spinner-large" />
          </Box>
        );
      case "success":
        return (
          <>
            <Grid item xs={12}>
              <GenericAlert
                name="success"
                header={config.cancelSuccessTitle}
                description={config.cancelSuccessMsg}
                showBackground={true}
              />
            </Grid>
            <Grid item xs={12}>
              {cancelCta}
            </Grid>
          </>
        );
      case "error":
        return (
          <>
            <Grid item xs={12}>
              {infoError ? (
                <GenericAlert
                  name="primary"
                  header={errMsg}
                  showBackground={true}
                />
              ) : (
                <GenericAlert
                  name="warning"
                  header={errMsg}
                  showBackground={true}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {cancelCta}
            </Grid>
          </>
        );
      default:
        return (
          <>
            <Grid item xs={12} sm={8}>
              <Button
                variant="super"
                label={config.cancelCtaLabel}
                size="medium"
                state="warning"
                onClick={cancelAction}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              {cancelCta}
            </Grid>
          </>
        );
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid container spacing={2} item md={8}>
        <Grid item xs={12}>
          <div className="heading20">{config.cancelPageTitle}</div>
        </Grid>
        <Grid item xs={12} sx={{ display: { xs: "block", md: "none" } }}>
          <Illustration name="illus-tc-store" className="custom-svg-class" />
        </Grid>
        <Grid item xs={12}>
          <div className="body16">
            Please confirm that you want to cancel your{" "}
            {apptDetails.appointmentType.toLowerCase()} appointment at{" "}
            {apptDetails.storeName}, {apptDetails.storeAddress},{" "}
            {apptDetails.storeSuburb} {apptDetails.storeState}{" "}
            {apptDetails.storePostcode} for {apptDetails.appointmentDate}{" "}
            {apptDetails.appointmentTime}
          </div>
        </Grid>
        {getFooter()}
      </Grid>
      <Grid item md={4} sx={{ display: { xs: "none", md: "block" } }}>
        <Illustration name="illus-tc-store" className="custom-svg-class" />
      </Grid>
    </Grid>
  );
};

export default Cancel;
