import React from "react";
import { Navbar } from "telstra-ui/components/navbar/Navbar";
import { SvgIcon } from "telstra-ui/components/svg/Icon";
import { Footer } from "telstra-ui/components/footer/Footer";
import styles from "./Layout.module.scss";
import config from "../config";

const Layout = ({ children }) => {
  return (
    <div className={styles.layout}>
      <Navbar id="tl-store-appts-nav-bar" className={styles.navbar}>
        <div className={styles.navFlex}>
          <SvgIcon name="icon-logo-blue" className={styles.tlIconLogo} />
          <div className={styles.navTitle}>{config.appHeader}</div>
        </div>
      </Navbar>
      <div className={styles.container}>{children}</div>
      <Footer
        className={styles.footer}
        content={<div className="heading10">{config.appFooter}</div>}
        contentPosition="center"
      ></Footer>
    </div>
  );
};

export default Layout;
