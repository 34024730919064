import React from "react";
import { useRouteError } from "react-router-dom";
import { ErrorPage } from "telstra-ui/components/error-page/ErrorPage";
import { Pictograms } from "telstra-ui/components/svg/Pictograms";
import Layout from "../Layout";

const ErrorUnknown = () => {
  const error = useRouteError();
  return (
    <Layout>
      <ErrorPage
        isModule={true}
        className="tl-error-page-container"
        header={<div className="heading28">Oops!</div>}
        subheader={
          <div className="tl-picto-error-page-subheader">
            {error.statusText || error.message}
          </div>
        }
        bodyImage={
          <Pictograms
            name="picto-error"
            className="tl-error-page-picto-svg-class"
          />
        }
      />
    </Layout>
  );
};

export default ErrorUnknown;
