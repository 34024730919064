const CONFIG = {
  // Common
  appHeader: process.env.REACT_APP_APP_HEADER,
  appFooter: process.env.REACT_APP_APP_FOOTER,
  malformedLink: process.env.REACT_APP_INVALID_LINK,
  bookingUrl: process.env.REACT_APP_BOOKING_URL,
  retrieveErrorMsg: process.env.REACT_APP_RETRIEVE_ERROR_MSG,
  updateErrorMsg: process.env.REACT_APP_UPDATE_ERROR_MSG,
  errMap: JSON.parse(process.env.REACT_APP_ERR_MAP || "{}"),
  infoErrorCodes: process.env.REACT_APP_INFO_ERROR_CODES,
  // Confirm page related
  confirmPageTitle: process.env.REACT_APP_CONFIRM_PAGE_TITLE,
  confirmCtaLabel: process.env.REACT_APP_CONFIRM_CTA_LABEL,
  cancelConfirmCtaLabel: process.env.REACT_APP_CANCEL_CONFIRM_CTA_LABEL,
  cancelConfirmCtaUrl: process.env.REACT_APP_CANCEL_CONFIRM_CTA_URL,
  confirmSuccessTitle: process.env.REACT_APP_CONFIRM_SUCCESS_TITLE,
  confirmSuccessMsg: process.env.REACT_APP_CONFIRM_SUCCESS_MSG,
  // Cancel page related
  cancelPageTitle: process.env.REACT_APP_CANCEL_PAGE_TITLE,
  cancelCtaLabel: process.env.REACT_APP_CANCEL_CTA_LABEL,
  cancelCancelCtaLabel: process.env.REACT_APP_CANCEL_CANCEL_CTA_LABEL,
  cancelCancelCtaUrl: process.env.REACT_APP_CANCEL_CANCEL_CTA_URL,
  cancelSuccessTitle: process.env.REACT_APP_CANCEL_SUCCESS_TITLE,
  cancelSuccessMsg: process.env.REACT_APP_CANCEL_SUCCESS_MSG
};

export default CONFIG;
