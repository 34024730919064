import React from "react";
import { Outlet } from "react-router-dom";
import Layout from "../../components/Layout";
import axios from "axios";
import { DateTime } from "luxon";
import config from "../../config";
import pupa from "pupa";

export const getLoader =
  (action) =>
  async ({ params }) => {
    const bookingRef = params.bookingRef;
    const correlationId = crypto.randomUUID();
    if (!bookingRef) {
      throw new Error(config.malformedLink);
    }
    try {
      const {
        data: { data: apptDetails },
      } = await axios.get(`${config.bookingUrl}${bookingRef}`, {
        params: {
          action,
        },
        headers: {
          "X-Correlation-ID": correlationId,
        },
      });
      apptDetails.appointmentTime = DateTime.fromFormat(
        apptDetails.appointmentTime,
        "HH:mm"
      ).toFormat("hh:mma");
      return {
        correlationId,
        bookingRef,
        apptDetails,
      };
    } catch (error) {
      let errMsg;
      try {
        const errObj = error.response.data.errors[0];
        errMsg = config.errMap[errObj.code];
      } catch (error) {
        // Suppress errMsg lookup
      }
      errMsg = errMsg || config.retrieveErrorMsg;
      throw new Error(pupa(errMsg, { action }));
    }
  };

const Root = () => {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default Root;
