import React from "react";
import { createBrowserRouter } from "react-router-dom";
import ErrorUnknown from "./components/errors/unknown";
import config from "./config";
import Root, { getLoader } from "./routes/root";
import Cancel from "./routes/root/cancel";
import Confirm from "./routes/root/confirm";

export default createBrowserRouter(
  [
    {
      path: "/",
      element: <Root />,
      loader: ({ request }) => {
        if (new URL(request.url).pathname === "/customer-booking") {
          throw new Error(config.malformedLink);
        }
        return {};
      },
      errorElement: <ErrorUnknown />,
      children: [
        {
          path: "confirm/:bookingRef",
          element: <Confirm />,
          loader: getLoader("confirm"),
        },
        {
          path: "cancel/:bookingRef",
          element: <Cancel />,
          loader: getLoader("cancel"),
        },
      ],
    },
  ],
  {
    basename: "/customer-booking",
  }
);
